import React from 'react'
import moment from 'moment'
import 'moment/min/locales'
import { FlexBox } from '@cimpress/react-components'
import DatePicker from '@cimpress/react-components/lib/DatePicker'

import styles from './DateInput.module.css'
import formStyles from '../RequestForm.module.css'
import { useAutoScroll } from '../../../hooks/useAutomaticScroll'
import { TimeInput } from '../TimeInput/TimeInput'

export default function DateInput({
  value,
  label,
  includeTime = false,
  onChange,
  required,
  errors,
}) {
  const automaticScrolling = useAutoScroll()

  return (
    <div className={formStyles.formPart} ref={automaticScrolling.elementRef}>
      <h5 className={formStyles.header}>{label}</h5>
      <FlexBox marginX="m" className={styles.shipDateInputBox}>
        <DatePicker
          value={moment(value.date)}
          onChange={(date) =>
            onChange({ ...value, date: date.format('Y-MM-DD') })
          }
          dateFormat="Y-MM-DD"
          timeFormat={false}
          closeOnSelect={true}
          required={required}
          onOpen={() => automaticScrolling.scrollToElement()}
        />
        {includeTime ? (
          <>
            <span className={styles.verticalCenter}>at</span>
            <TimeInput
              value={value.time}
              onChange={(time) => onChange({ ...value, time })}
              errors={errors && errors.time}
              required
            />{' '}
          </>
        ) : (
          ''
        )}
      </FlexBox>
    </div>
  )
}
