import React from 'react'
import { Radio, RadioGroup } from '@cimpress/react-components'

import AddressInput from './AddressInput'
import PickupPointInput from './PickupPointInput'

import formStyles from '../RequestForm.module.css'

export const DESTINATION_TYPES = {
  ADDRESS: 'address',
  PICKUP_POINT: 'pickupPointUrl',
}

export default function DestinationInput({
  value,
  onChange,
  errors,
  required,
}) {
  return (
    <div className={formStyles.formPart}>
      <div className="row">
        <div className="col col-xs-12">
          <h5 className={formStyles.header}>Shipping to</h5>
          <RadioGroup
            name="destinationType"
            inline
            onChange={(e, destinationType) => {
              onChange({ ...value, destinationType })
            }}
            defaultSelected={value.destinationType}
          >
            <Radio
              className={formStyles.checkbox}
              label="Regular address"
              value={DESTINATION_TYPES.ADDRESS}
            />
            <Radio
              className={formStyles.checkbox}
              label="Pickup point"
              value={DESTINATION_TYPES.PICKUP_POINT}
            />
          </RadioGroup>
        </div>
      </div>
      {value.destinationType === DESTINATION_TYPES.PICKUP_POINT ? (
        <PickupPointInput
          value={value.pickupPointUrl}
          onChange={(pickupPointUrl) => onChange({ ...value, pickupPointUrl })}
          required={required}
          errors={errors && errors.pickupPointUrl}
        />
      ) : (
        <AddressInput
          value={value.address}
          onChange={(address) => onChange({ ...value, address })}
          required={required}
        />
      )}
    </div>
  )
}
