import React, { useContext, useState } from 'react'
import Settings from '@cimpress-technology/react-platform-settings'
import RadioGroup from '@cimpress/react-components/lib/RadioGroup'
import Radio from '@cimpress/react-components/lib/Radio'
import AuthContext from './context/AuthContext'
import AppSettingsContext from './context/AppSettingsContext'

import { lengthUnits } from './constants/lengthUnits'
import { weightUnits } from './constants/weightUnits'

export default function AppSettings({ setAppSettings }) {
  const appSettings = useContext(AppSettingsContext)
  const [lengthUnit, setLengthUnit] = useState(appSettings.lengthUnit)
  const [weightUnit, setWeightUnit] = useState(appSettings.weightUnit)
  const { token } = useContext(AuthContext)

  const onSave = () => {
    const newAppSettings = {
      lengthUnit,
      weightUnit,
    }
    setAppSettings(newAppSettings)
  }

  return (
    <Settings
      authToken={token}
      appSettingsContent={
        <>
          <h5>Default Length Unit</h5>
          <RadioGroup
            onChange={(e, unit) => setLengthUnit(unit)}
            inline
            defaultSelected={appSettings.lengthUnit || lengthUnits[0].value}
            name="Length Unit"
          >
            {lengthUnits.map(({ label, value }) => (
              <Radio key={value} label={label} value={value} />
            ))}
          </RadioGroup>
          <h5>Default Weight Unit</h5>
          <RadioGroup
            onChange={(e, unit) => setWeightUnit(unit)}
            inline
            defaultSelected={appSettings.weightUnit || weightUnits[0].value}
            name="Weight Unit"
          >
            {weightUnits.map(({ label, value }) => (
              <Radio key={value} label={label} value={value} />
            ))}
          </RadioGroup>
        </>
      }
      onSave={onSave}
    />
  )
}
