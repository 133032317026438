import React from 'react'
import { shapes } from '@cimpress/react-components'

const { Spinner } = shapes

const GlobalSpinner = ({ children, visible }) => {
  return visible ? <Spinner fullPage /> : children
}

export default GlobalSpinner
