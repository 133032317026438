export default {
  bigofficesuperstorefast: {
    value: 'recs:cap:bigofficesuperstorefast',
    label: 'Big office superstore fast',
  },
  bigofficesuperstorerush: {
    value: 'recs:cap:bigofficesuperstorerush',
    label: 'Big office superstore rush',
  },
  binnableral: { value: 'recs:cap:binnableral', label: 'Binnable Ral' },
  canshiptokiala: {
    value: 'recs:cap:canshiptokiala',
    label: 'Can ship to Kiala',
  },
  canshiptononkialaaddress: {
    value: 'recs:cap:canshiptononkialaaddress',
    label: 'Can ship to non Kiala address',
  },
  cashOnDelivery: {
    value: 'recs:cap:cashondelivery',
    label: 'Cash on delivery',
    isParametric: true,
  },
  declaredtouscustoms: {
    value: 'recs:cap:declaredtouscustoms',
    label: 'Declared to US customs',
  },
  declaretofda: { value: 'recs:cap:declaretofda', label: 'Declare to FDA' },
  'delivery-to-neighbours': {
    value: 'recs:cap:delivery-to-neighbours',
    label: 'Delivery to neighbours',
  },
  fast: { value: 'recs:cap:fast', label: 'Fast' },
  fedexofficefast: {
    value: 'recs:cap:fedexofficefast',
    label: 'Fedex office fast',
  },
  fedexofficerush: {
    value: 'recs:cap:fedexofficerush',
    label: 'Fedex office rush',
  },
  'flexible-delivery': {
    value: 'recs:cap:flexible-delivery',
    label: 'Flexiblr delivery',
  },
  grandtoyfast: { value: 'recs:cap:grandtoyfast', label: 'Grand toy fast' },
  grandtoyrush: { value: 'recs:cap:grandtoyrush', label: 'Grand toy rush' },
  'hazardous-materials': {
    value: 'recs:cap:hazardous-materials',
    label: 'Hazardous materials',
  },
  insurance: { value: 'recs:cap:insurance', label: 'iIsurance' },
  intuitfast: { value: 'recs:cap:intuitfast', label: 'Intuit fast' },
  intuitnormal: { value: 'recs:cap:intuitnormal', label: 'Intuit normal' },
  intuitrush: { value: 'recs:cap:intuitrush', label: 'Intuit rush' },
  intuitslow: { value: 'recs:cap:intuitslow', label: 'Intuit slow' },
  keepatplant: { value: 'recs:cap:keepatplant', label: 'Keep at plant' },
  'letterbox-package': {
    value: 'recs:cap:letterbox-package',
    label: 'Letterbox package',
  },
  lithiumionbattery: {
    value: 'recs:cap:lithiumionbattery',
    label: 'Lithium ion battery',
  },
  mailingservicesremailer: {
    value: 'recs:cap:mailingservicesremailer',
    label: 'Mailing services remailer',
  },
  nonpoboxusa: { value: 'recs:cap:nonpoboxusa', label: 'Non PO box USA' },
  normal: { value: 'recs:cap:normal', label: 'Normal' },
  notification: { value: 'recs:cap:notification', label: 'Notification' },
  officedepotfast: {
    value: 'recs:cap:officedepotfast',
    label: 'Office depot fast',
  },
  officedepotrush: {
    value: 'recs:cap:officedepotrush',
    label: 'Office depot rush',
  },
  officemaxfast: { value: 'recs:cap:officemaxfast', label: 'Office max fast' },
  officemaxrush: { value: 'recs:cap:officemaxrush', label: 'Office max rush' },
  'pallet-shipping': {
    value: 'recs:cap:pallet-shipping',
    label: 'Pallet shipping',
  },
  'pickup-point-delivery': {
    value: 'recs:cap:pickup-point-delivery',
    label: 'Pickup point delivery',
  },
  poboxgermany: { value: 'recs:cap:poboxgermany', label: 'PO box Germany' },
  poboxrestofworld: {
    value: 'recs:cap:poboxrestofworld',
    label: 'PO box rest of world',
  },
  poboxusa: { value: 'recs:cap:poboxusa', label: 'poboxusa' },
  'post-box-delivery': {
    value: 'recs:cap:post-box-delivery',
    label: 'Post box delivery',
  },
  rush: { value: 'recs:cap:rush', label: 'rush' },
  'saturday-delivery': {
    value: 'recs:cap:saturday-delivery',
    label: 'Saturday delivery',
  },
  scheduledDelivery: {
    value: 'recs:cap:scheduled-delivery',
    label: 'Scheduled delivery',
    isParametric: true,
  },
  shipsaloneral: { value: 'recs:cap:shipsaloneral', label: 'Ships alone Ral' },
  'signature-on-delivery': {
    value: 'recs:cap:signature-on-delivery',
    label: 'Signature on delivery',
  },
  signaturerequired: {
    value: 'recs:cap:signaturerequired',
    label: 'Signature required',
  },
  slow: { value: 'recs:cap:slow', label: 'Slow' },
  staplesbusinessdepotfast: {
    value: 'recs:cap:staplesbusinessdepotfast',
    label: 'Staples business depot fast',
  },
  staplesbusinessdepotrush: {
    value: 'recs:cap:staplesbusinessdepotrush',
    label: 'Staples business depot rush',
  },
  staplesfast: { value: 'recs:cap:staplesfast', label: 'Staples fast' },
  staplesrush: { value: 'recs:cap:staplesrush', label: 'Staples rush' },
  trackable: { value: 'recs:cap:trackable', label: 'Trackable' },
}
