import React from 'react'
import { TextField, RadioGroup, Radio } from '@cimpress/react-components'
import ReactCountryFlag from 'react-country-flag'
import SelectValue from '../../SelectValue/SelectValue'

import formStyles from '../RequestForm.module.css'
import styles from './AddressInput.module.css'

import AVAILABLE_COUNTRIES from './availableCountries'

const ADDRESS_TYPE_COUNTRIES = ['US']
export const ADDRESS_TYPES = {
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
}

export default function AddressInput({ value, onChange, required }) {
  return (
    <>
      <div className="row">
        <div className={`col-xs-6 ${styles.colLeft}`}>
          <SelectValue
            containerClassName={styles.lowBottomMargin}
            label="Country"
            value={value.countryCode}
            onChange={(countryCode) => {
              onChange({ ...value, countryCode })
            }}
            options={AVAILABLE_COUNTRIES}
            getOptionLabel={(option) => (
              <div>
                <ReactCountryFlag countryCode={option.value} />
                {option.countryName}
              </div>
            )}
            required={required}
            filterOption={(option, query) =>
              option.data.countryName
                .toLowerCase()
                .includes(query.toLowerCase())
            }
            tether
          />
        </div>
        <div className={`col-xs-6 ${styles.colRight}`}>
          <TextField
            className={styles.lowBottomMargin}
            label="Postal Code"
            value={value.postalCode}
            onChange={(result) =>
              onChange({ ...value, postalCode: result.target.value })
            }
          />
        </div>
      </div>
      {ADDRESS_TYPE_COUNTRIES.includes(value.countryCode) ? (
        <>
          <div className="row">
            <div className={`col col-xs-12 ${styles.header}`}>
              This address is
            </div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <RadioGroup
                name="destinationType"
                inline
                onChange={(e, addressType) =>
                  onChange({ ...value, addressType })
                }
                defaultSelected={value.addressType}
              >
                <Radio
                  className={formStyles.checkbox}
                  label="Residential"
                  value={ADDRESS_TYPES.RESIDENTIAL}
                />
                <Radio
                  className={formStyles.checkbox}
                  label="Commercial"
                  value={ADDRESS_TYPES.COMMERCIAL}
                />
              </RadioGroup>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
