import React, { createContext } from 'react'
import useCarrierServices from '../hooks/useCarrierServices'

export const CarrierServicesContext = createContext({
  error: undefined,
  isLoading: false,
  carrierServices: undefined,
  load: async () => {
    throw new Error('CarrierServicesContext not defined')
  },
})

export const CarrierServicesProvider = ({ children, value }) => {
  const { load, error, isLoading, carrierServices } = useCarrierServices()

  const context = {
    carrierServices: value || carrierServices,
    error,
    isLoading,
    load,
  }

  return (
    <CarrierServicesContext.Provider value={context}>
      {children}
    </CarrierServicesContext.Provider>
  )
}

export default CarrierServicesContext
