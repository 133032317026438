import React from 'react'
import { ValueInput } from '../ValueInput/ValueInput'

const CashOnDeliveryParams = (props) => {
  return (
    <>
      <h5>For Cash on Delivery - the collectible amount is</h5>
      <ValueInput {...props} />
    </>
  )
}

export default CashOnDeliveryParams
