import React, { useContext, useState } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import Alert from '@cimpress/react-components/lib/Alert'
import { renderDisplayCurrency } from '../../services/currency'
import LocaleContext from '../../context/LocaleContext'
import styles from '../ResultCard/ResultCard.module.css'
import {
  Label,
  TabCard,
  ReactTablev6,
  shapes,
} from '@cimpress/react-components'
import _ from 'lodash'
import moment from 'moment'
import resultPaneStyles from '../ResultPane/ResultPane.module.css'
const { Robot } = shapes

const renderCostRange = (minCost, maxCost, currencyCode, locale) => {
  if (minCost === maxCost) {
    return `${renderDisplayCurrency(minCost, currencyCode, locale)}`
  } else {
    return `between ${renderDisplayCurrency(
      minCost,
      currencyCode,
      locale
    )} and ${renderDisplayCurrency(maxCost, currencyCode, locale)}`
  }
}

const renderDeliveryDateAlert = (route) => {
  if (route.shipmentsWithoutDatesData === route.routeQuantity) {
    return (
      <Alert
        type={'danger'}
        message={`This carrier service cannot deliver on any date`}
        dismissible={false}
      />
    )
  } else if (route.earliestDeliveryDate === route.latestDeliveryDate) {
    return (
      <Alert
        type={route.shipmentsWithoutDatesData ? 'warning' : 'info'}
        message={
          <>
            Able to deliver the requested package on{' '}
            <Label type="primary" text={route.earliestDeliveryDate} />{' '}
            {route.shipmentsWithoutDatesData > 0 ? (
              <>
                but there were also{' '}
                <Label type="primary" text={route.shipmentsWithoutDatesData} />{' '}
                plans with problems
              </>
            ) : (
              ''
            )}
          </>
        }
        dismissible={false}
      />
    )
  } else {
    return (
      <Alert
        type={route.shipmentsWithoutDatesData ? 'warning' : 'info'}
        message={
          <>
            Able to deliver the requested package on{' '}
            <Label
              type="primary"
              text={`${route.routeQuantity - route.shipmentsWithoutDatesData}`}
            />{' '}
            dates between{' '}
            <Label type="primary" text={route.earliestDeliveryDate} /> and{' '}
            <Label type="primary" text={route.latestDeliveryDate} />{' '}
            {route.shipmentsWithoutDatesData > 0 ? (
              <>
                but there were also{' '}
                <Label type="primary" text={route.shipmentsWithoutDatesData} />{' '}
                plans with problems{' '}
              </>
            ) : (
              ''
            )}{' '}
          </>
        }
        dismissible={false}
      />
    )
  }
}

const renderCostAlert = (route, locale) => {
  if (route.shipmentsWithoutCostData === route.routeQuantity) {
    return (
      <Alert
        type={'danger'}
        message={`This carrier service is unable to generate cost to ship the package`}
        dismissible={false}
      />
    )
  } else {
    return (
      <Alert
        type={route.shipmentsWithoutCostData ? 'warning' : 'info'}
        message={
          <>
            To ship using this service will cost{' '}
            <Label
              type="primary"
              text={renderCostRange(
                route.minShippingCost,
                route.maxShippingCost,
                route.currencyCode,
                locale
              )}
            />
            {route.shipmentsWithoutCostData ? (
              <>
                but there were also{' '}
                <Label type="primary" text={route.shipmentsWithoutCostData} />{' '}
                plans with problems
              </>
            ) : (
              ''
            )}{' '}
          </>
        }
        dismissible={false}
      />
    )
  }
}

function renderTabs(tabs, noDataText) {
  if (!tabs.length) {
    return noDataText
  }
  return tabs.length > 1 ? (
    <TabCard tabs={tabs} selectedIndex={0} />
  ) : (
    tabs[0].block
  )
}
function renderRouteSelection(
  routes,
  setSelectedRoute,
  routesFilter,
  setRoutesFilter
) {
  return (
    <>
      <TextField
        label="Filter by Carrier Service Key"
        value={routesFilter}
        onChange={(e) => setRoutesFilter(e.target.value)}
      />
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <table className="table table-hover">
          <tbody>
            {routes
              .filter(
                (route) => !routesFilter || route.id.includes(routesFilter)
              )
              .map((route) => (
                <tr
                  key={route.id}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedRoute(route)}
                >
                  <td style={{ textAlign: 'center' }}>
                    {route.isValid ? (
                      <i className="text-success fa fa-circle" />
                    ) : (
                      <i className="text-danger fa fa-circle" />
                    )}
                  </td>
                  <td>
                    <b>{route.name}</b>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

function renderCostTables(breakdowns, currencyCode, locale) {
  const tabs = Object.values(breakdowns).map(({ breakdown }, i) => {
    return {
      name: `#${i + 1}`,
      block: (
        <ReactTablev6
          showPageSizeOptions={false}
          className={styles.table}
          pageSize={20}
          minRows={0}
          columns={[
            {
              Header: 'Cost Types',
              accessor: 'name',
              sortable: false,
            },
            {
              Header: '',
              accessor: 'amount',
              sortable: false,
              Cell: function renderCarrierService(row) {
                return renderDisplayCurrency(row.value, currencyCode, locale)
              },
            },
          ]}
          data={breakdown}
        />
      ),
    }
  })
  return renderTabs(tabs)
}
function renderExclusionReasons({ exclusionReasons }) {
  const tabs = Object.values(exclusionReasons)
    .filter(({ data }) => data.length)
    .map(({ data, shipDates }, i) => {
      return {
        name: `#${i + 1}`,
        block: (
          <div>
            {shipDates ? (
              <h5>
                Exclusion reasons for shipments shipped on{' '}
                {shipDates.map((shipDate) => (
                  <Label
                    key={shipDate}
                    type="primary"
                    text={moment.parseZone(shipDate).format('LLLL')}
                  />
                ))}
              </h5>
            ) : null}

            <ReactTablev6
              showPageSizeOptions={false}
              className={styles.table}
              pageSize={20}
              minRows={0}
              columns={[
                {
                  Header: 'Exclusion Reason',
                  accessor: 'name',
                  sortable: false,
                  Cell: function renderCarrierService(row) {
                    return (
                      <>
                        <h4>{row.original.name}</h4>
                        {row.original.additionalInformation}
                      </>
                    )
                  },
                },
              ]}
              data={data}
            />
          </div>
        ),
      }
    })
  return renderTabs(
    tabs,
    'This leg is correctly set up and has all necessary configuration'
  )
}

const ConfigurationDiagnosticsResult = ({ result: routes }) => {
  const { locale } = useContext(LocaleContext)
  const [selectedRoute, setSelectedRoute] = useState(routes[0])
  const [routesFilter, setRoutesFilter] = useState(undefined)

  if (_.isEmpty(routes)) {
    return (
      <div
        className={`${resultPaneStyles.resultContainer} ${resultPaneStyles.centerVertically}`}
      >
        <span className={resultPaneStyles.centerIcon}>
          <Robot bsStyle="warning" alternate />
        </span>
        <p className={resultPaneStyles.resultText}>
          Unfortunately, there are is no way how this location could ship
          described shipment.
          <br />
          Try to modify the request or check the configuration in{' '}
          <a
            href="https://qcm.qp.cimpress.io/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Carrier contract manager
          </a>{' '}
          that it contains at least one carrier service
        </p>
      </div>
    )
  }
  return (
    <div className={`card ${styles.fullWidth}`}>
      <div className="card-block">
        <div className="row">
          <div className="col-md-4">
            {renderRouteSelection(
              routes,
              setSelectedRoute,
              routesFilter,
              setRoutesFilter
            )}
          </div>
          <div className="col-md-8">
            <h2>{selectedRoute.name}</h2>
            <br />
            {renderDeliveryDateAlert(selectedRoute)}
            {renderCostAlert(selectedRoute, locale)}

            {_.get(selectedRoute, 'breakdowns')
              ? renderCostTables(
                  selectedRoute.breakdowns,
                  selectedRoute.currencyCode,
                  locale
                )
              : ''}

            {selectedRoute.legs.map((leg, index, legs) => (
              <div key={leg.carrierServiceKey + index}>
                <h3>
                  <b>
                    {' '}
                    {legs.length > 1 ? `${index + 1}. leg: ` : ''} {leg.name}
                  </b>
                </h3>
                {_.get(leg, 'exclusionReasons')
                  ? renderExclusionReasons(leg)
                  : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigurationDiagnosticsResult
