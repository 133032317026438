import React from 'react'
import { shapes } from '@cimpress/react-components'
import styles from './ResultPane.module.css'
const { Spinner } = shapes

export default function ResultPane({
  result,
  isLoading,
  ResultComponent,
  resultName,
  AppIcon,
}) {
  if (isLoading) {
    return (
      <div className={`${styles.resultContainer} ${styles.centerVertically}`}>
        <span className={styles.loading}>
          <Spinner size={'large'} />
        </span>
        <p>Calculating the {resultName}</p>
      </div>
    )
  } else if (!result) {
    return (
      <div className={`${styles.resultContainer} ${styles.centerVertically}`}>
        <span className={styles.centerIcon}>{AppIcon}</span>
        <p className={styles.resultText}>
          Tell us what you would like to ship
          <br />
          and we will show you the {resultName}.
        </p>
      </div>
    )
  } else {
    return (
      <div className={`${styles.resultContainer}`}>
        <ResultComponent result={result} />
      </div>
    )
  }
}
