import React from 'react'
import { NavTab, NavTabItem } from '@cimpress/react-components'

import styles from './RequestInputTypeSwitcher.module.css'

export const REQUEST_INPUT_TYPE = {
  FORM: 'form',
  RAW: 'raw',
}
export default function RequestInputTypeSwitcher({
  value,
  onChange = () => {},
}) {
  return (
    <NavTab className={styles.navtab}>
      <NavTabItem active={value === REQUEST_INPUT_TYPE.RAW}>
        <button onClick={() => onChange(REQUEST_INPUT_TYPE.RAW)}>
          Raw request
        </button>
      </NavTabItem>
      <NavTabItem active={value === REQUEST_INPUT_TYPE.FORM}>
        <button onClick={() => onChange(REQUEST_INPUT_TYPE.FORM)}>
          Form view
        </button>
      </NavTabItem>
    </NavTab>
  )
}
