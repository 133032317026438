import styles from '../RequestForm.module.css'
import {
  IconArrowDownAlt,
  IconArrowUpAlt,
} from '@cimpress-technology/react-streamline-icons'

import React, { useEffect } from 'react'
import { useAutoScroll } from '../../../hooks/useAutomaticScroll'

export default function ShowMoreInput({
  labelShow,
  labelHide,
  children,
  isOpened,
  onChange,
}) {
  const { scrollToElement, elementRef } = useAutoScroll()

  useEffect(() => {
    if (isOpened) {
      scrollToElement()
    }
  }, [isOpened, scrollToElement])

  return isOpened ? (
    <>
      <div
        ref={elementRef}
        className={`clickable btn-link ${styles.bottomMargin} `}
        onClick={() => onChange(!isOpened)}
      >
        {labelHide} <IconArrowUpAlt />{' '}
      </div>
      {children}
    </>
  ) : (
    <div
      className={`clickable btn-link ${styles.bottomMargin}`}
      onClick={() => onChange(!isOpened)}
    >
      {labelShow} <IconArrowDownAlt />
    </div>
  )
}
