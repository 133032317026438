import { useContext, useState, useCallback } from 'react'
import _ from 'lodash'
import {
  loadDeliverableDatesPackages,
  loadDeliverableDatesPacks,
} from '../services/planning'
import AuthContext from '../context/AuthContext'
import { isPackagesRequest, isPackRequest } from '../services/packsOrPackages'

const loadDeliverableDates = (request, ...args) => {
  let loadDeliverableDates = _.noop
  if (isPackRequest(request)) {
    loadDeliverableDates = loadDeliverableDatesPacks
  } else if (isPackagesRequest(request)) {
    loadDeliverableDates = loadDeliverableDatesPackages
  } else {
    throw new Error('Unknown request type')
  }
  return loadDeliverableDates(request, ...args)
}

const useDeliverableDates = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState(undefined)
  const { token } = useContext(AuthContext)
  const load = useCallback(
    async (request) => {
      try {
        setData(undefined)
        setError(undefined)
        setIsLoading(true)
        const { deliverableDates } = await loadDeliverableDates(request, token)
        setData(deliverableDates)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [token]
  )
  const clear = useCallback(() => {
    setData(undefined)
    setError(undefined)
  }, [])
  return { data, clear, isLoading, error, load }
}

export default useDeliverableDates
