import React, { createContext, useContext } from 'react'
import useFormData from '../hooks/useFormFields'
import { ADDRESS_TYPES } from '../components/RequestForm/DestinationInput/AddressInput'
import { STRATEGY_PRIORITY } from '../components/RequestForm/StrategyInput/StrategyInput'
import LocaleContext from './LocaleContext'
import moment from 'moment'
import { DESTINATION_TYPES } from '../components/RequestForm/DestinationInput/DestinationInput'
import { SHIPMENT_TYPES } from '../components/RequestForm/ShipmentInput/ShipmentInput'
import AVAILABLE_CAPABILITIES from '../components/RequestForm/RequirementsInput/availableCapabilities'
import AppSettingsContext from './AppSettingsContext'
import { MATCHING_MODES } from '../components/RequestForm/RequirementsInput/ScheduledDeliveryParams'
import { REQUEST_INPUT_TYPE } from '../components/RequestForm/RequestInputTypeSwitcher/RequestInputTypeSwitcher'
import { useLocation } from 'react-router-dom'
export const FormContext = createContext({
  fields: {},
})

function formatJSONifValid(str) {
  if (!str) {
    return str
  }
  try {
    return JSON.stringify(JSON.parse(str), undefined, 2)
  } catch (e) {
    return str
  }
}

export const FormContextProvider = ({ children }) => {
  const now = moment()
  const rawRequest = formatJSONifValid(
    new URLSearchParams(useLocation().search).get('r')
  )

  const {
    countryCode: defaultCountryCode,
    currency: defaultCurrencyCode,
  } = useContext(LocaleContext)
  const { weightUnit, lengthUnit } = useContext(AppSettingsContext)

  const { fields, updateFields } = useFormData({
    requestInputType: rawRequest
      ? REQUEST_INPUT_TYPE.RAW
      : REQUEST_INPUT_TYPE.FORM,
    rawRequestRecommendations: rawRequest || `{}`,
    rawRequestDeliveryDates: rawRequest || `{}`,
    rawRequestConfigurationDiagnostics: rawRequest || `{}`,
    fulfillmentLocation: undefined, // example of predefined location { fulfillmentLocationId: 'b7uqagcx0nw', timeZone: 'Europe/London' ),
    shippingDestination: {
      destinationType: DESTINATION_TYPES.ADDRESS,
      pickupPointUrl: '',
      address: {
        countryCode: defaultCountryCode,
        postalCode: '',
        addressType: ADDRESS_TYPES.RESIDENTIAL,
      },
    },
    shipment: {
      shipmentType: SHIPMENT_TYPES.PARCEL,
      shipmentItems: [
        {
          dimension1: '',
          dimension2: '',
          dimension3: '',
          weight: '',
          hasShipmentValue: false,
          lengthUnit,
          weightUnit,
        },
      ],
    },
    require: {
      carrierServices: [],
      capabilities: [],
      capabilityParams: {
        [AVAILABLE_CAPABILITIES.cashOnDelivery.value]: {
          scalar: '',
          currency: defaultCurrencyCode,
        },
        [AVAILABLE_CAPABILITIES.scheduledDelivery.value]: {
          matchingMode: MATCHING_MODES.before.value,
          before: '',
        },
      },
    },
    include: { isActive: false, carrierServices: [] },
    prefer: { carrierServices: [] },
    priorities: {
      onTimePriorities: [
        STRATEGY_PRIORITY.PREFERENCE.value,
        STRATEGY_PRIORITY.LOWEST_COST.value,
        STRATEGY_PRIORITY.LATEST_SHIP_DATE.value,
        STRATEGY_PRIORITY.EARLIEST_DELIVERY_DATE.value,
      ],
      lateDeliveryPriorities: [
        STRATEGY_PRIORITY.EARLIEST_DELIVERY_DATE.value,
        STRATEGY_PRIORITY.PREFERENCE.value,
        STRATEGY_PRIORITY.LOWEST_COST.value,
        STRATEGY_PRIORITY.LATEST_SHIP_DATE.value,
      ],
    },
    isShowMoreOpened: false,
    earliestShipDateTime: {
      date: now.format('Y-MM-DD'),
      time: now.format('HH:mm'),
    },
    expectedDeliveryDate: {
      date: now.format('Y-MM-DD'),
    },
    sortAndLimit: {
      maxNumberOfRecommendations: '3',
      costToSort: 'totalCost',
    },
    autoSubmit: !!rawRequest,
  })
  const context = { fields, updateFields }

  return <FormContext.Provider value={context}>{children}</FormContext.Provider>
}

export default FormContext
