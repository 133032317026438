import _ from 'lodash'
import { DESTINATION_TYPES } from '../DestinationInput/DestinationInput'
import { SHIPMENT_TYPES } from '../ShipmentInput/ShipmentInput'
import AVAILABLE_CAPABILITIES from '../RequirementsInput/availableCapabilities'
import moment from 'moment-timezone'
import {
  parseTime,
  dateTimeValidator,
  fulfillmentLocationValidator,
  getErrors,
  rawRequestValidator,
  requireValidator,
  shipmentValidator,
  shippingDestinationValidator,
  sortAndLimitValidator,
} from './formErrorHandling'
import { REQUEST_INPUT_TYPE } from '../RequestInputTypeSwitcher/RequestInputTypeSwitcher'

export function getFulfillmentLocationId(fields) {
  return {
    fulfillmentLocationId: fields.fulfillmentLocation.fulfillmentLocationId,
  }
}

function getShippingDestination(fields) {
  let result
  if (
    fields.shippingDestination.destinationType === DESTINATION_TYPES.ADDRESS
  ) {
    result = {
      shippingDestination: {
        address: {
          countryCode: fields.shippingDestination.address.countryCode,
          postalCode: fields.shippingDestination.address.postalCode,
          addressType: fields.shippingDestination.address.addressType,
        },
      },
    }
  } else {
    result = {
      shippingDestination: {
        pickupPointUrl: fields.shippingDestination.pickupPointUrl,
      },
    }
  }
  return result
}

function getShipment(fields) {
  let result
  if (
    [SHIPMENT_TYPES.PARCEL, SHIPMENT_TYPES.PALLET].includes(
      fields.shipment.shipmentType
    )
  ) {
    result = {
      packages: fields.shipment.shipmentItems.map((item) => ({
        weight: `${item.weight} ${item.weightUnit}`,
        dimension1: `${item.dimension1} ${item.lengthUnit}`,
        dimension2: `${item.dimension2} ${item.lengthUnit}`,
        dimension3: `${item.dimension3} ${item.lengthUnit}`,
        ...(item.hasShipmentValue && {
          packageValue: {
            value: item.shipmentValue.scalar,
            currencyCode: item.shipmentValue.currency,
          },
        }),
        packageType: fields.shipment.shipmentType,
      })),
    }
  }

  return result
}

function getPriorities(fields) {
  return {
    priorities: {
      onTimePriorities: fields.priorities.onTimePriorities,
      lateDeliveryPriorities: fields.priorities.lateDeliveryPriorities,
    },
  }
}
function getCostToSort(fields) {
  return {
    costToSort: fields.sortAndLimit.costToSort,
  }
}

function getMaxNumberOfRecommendations(fields) {
  return {
    maxNumberOfRecommendations: Number(
      fields.sortAndLimit.maxNumberOfRecommendations
    ),
  }
}

function getExpectedDeliveryDate(fields) {
  return {
    expectedDeliveryDate: fields.expectedDeliveryDate.date,
  }
}

export function getEarliestShipDateTime(fields) {
  const { timeZone } = fields.fulfillmentLocation || {}
  const time = parseTime(fields.earliestShipDateTime.time)
  return {
    earliestShipDateTime: moment
      .tz(fields.earliestShipDateTime.date, timeZone)
      .set({
        hour: time.get('hour'),
        minute: time.get('minute'),
      })
      .toISOString(true),
  }
}

function getInclude(fields) {
  if (fields.include.isActive) {
    return {
      include: [
        ...fields.include.carrierServices.map((item) => ({
          carrierServiceKey: item,
        })),
      ],
    }
  }
}
export const getCapabilityParam = (fields, capability) => {
  switch (capability) {
    case AVAILABLE_CAPABILITIES.cashOnDelivery.value:
      return {
        capabilityKey: capability,
        capabilityParams: [
          {
            name: 'collectableAmount',
            value: fields.require.capabilityParams[capability].scalar,
          },
          {
            name: 'currency',
            value: fields.require.capabilityParams[capability].currency,
          },
        ],
      }

    case AVAILABLE_CAPABILITIES.scheduledDelivery.value: {
      const { matchingMode, before } = fields.require.capabilityParams[
        capability
      ]
      const beforeSanitized = parseTime(before).format('HHmm')
      return {
        capabilityKey: `${capability}:${matchingMode}:${beforeSanitized}`,
      }
    }
    default:
      return { capabilityKey: capability }
  }
}
export function getRequire(fields) {
  return {
    require: [
      ...fields.require.carrierServices.map((item) => ({
        carrierServiceKey: item,
      })),
      ...fields.require.capabilities
        .map((capability) =>
          Object.values(AVAILABLE_CAPABILITIES)
            .map((availableCapability) => availableCapability.value)
            .includes(capability)
            ? capability
            : `user:${capability}`
        )
        .reduce((acc, curr) => {
          acc.push(getCapabilityParam(fields, curr))
          return acc
        }, []),
    ],
  }
}

function getPrefer(fields) {
  return {
    prefer: [
      ...fields.prefer.carrierServices.map((item) => ({
        carrierServiceKey: item,
      })),
    ],
  }
}

const createGetRawRequest = (namespace) => (fields) => {
  try {
    return JSON.parse(fields[namespace])
  } catch (e) {
    return null
  }
}

export function convertRecommendationsFormFieldsToRequest(
  fields,
  hardcodedFields,
  swaggerValidate
) {
  return convertRecommendationsLikeFormFieldsToRequest(
    fields,
    hardcodedFields,
    'rawRequestRecommendations',
    swaggerValidate
  )
}

export function convertConfigurationDiagnosticsFormFieldsToRequest(
  fields,
  hardcodedFields,
  swaggerValidate
) {
  return convertRecommendationsLikeFormFieldsToRequest(
    fields,
    hardcodedFields,
    'rawRequestConfigurationDiagnostics',
    swaggerValidate
  )
}

function convertRecommendationsLikeFormFieldsToRequest(
  fields,
  hardcodedFields,
  rawRequestFieldName,
  swaggerValidate
) {
  return convertFieldsToRequest(
    fields,
    {
      [REQUEST_INPUT_TYPE.FORM]: [
        getFulfillmentLocationId,
        getShippingDestination,
        getShipment,
        getPriorities,
        getCostToSort,
        getMaxNumberOfRecommendations,
        getEarliestShipDateTime,
        getExpectedDeliveryDate,
        getInclude,
        getRequire,
        getPrefer,
      ],
      [REQUEST_INPUT_TYPE.RAW]: [createGetRawRequest(rawRequestFieldName)],
    },

    {
      [REQUEST_INPUT_TYPE.FORM]: [
        fulfillmentLocationValidator('fulfillmentLocation'),
        shippingDestinationValidator('shippingDestination'),
        shipmentValidator('shipment'),
        dateTimeValidator('earliestShipDateTime'),
        dateTimeValidator('expectedDeliveryDate'),
        requireValidator('require'),
        sortAndLimitValidator('sortAndLimit'),
      ],
      [REQUEST_INPUT_TYPE.RAW]: [
        rawRequestValidator(
          rawRequestFieldName,
          'swagger#/components/schemas/v5ShippingRecommendationPackagesRequest',
          'swagger#/components/schemas/v5ShippingRecommendationPacksRequest',
          swaggerValidate
        ),
      ],
    },
    hardcodedFields
  )
}

export function convertDeliveryDatesFormFieldsToRequest(
  fields,
  hardcodedFields,
  swaggerValidate
) {
  return convertFieldsToRequest(
    fields,
    {
      [REQUEST_INPUT_TYPE.FORM]: [
        getFulfillmentLocationId,
        getShippingDestination,
        getShipment,
        getEarliestShipDateTime,
        getInclude,
        getRequire,
      ],
      [REQUEST_INPUT_TYPE.RAW]: [
        createGetRawRequest('rawRequestDeliveryDates'),
      ],
    },
    {
      [REQUEST_INPUT_TYPE.FORM]: [
        fulfillmentLocationValidator('fulfillmentLocation'),
        shippingDestinationValidator('shippingDestination'),
        shipmentValidator('shipment'),
        dateTimeValidator('earliestShipDateTime'),
        requireValidator('require'),
      ],
      [REQUEST_INPUT_TYPE.RAW]: [
        rawRequestValidator(
          'rawRequestDeliveryDates',
          'swagger#/components/schemas/v2DeliverableDatesPackagesRequest',
          'swagger#/components/schemas/v2DeliverableDatesPacksRequest',
          swaggerValidate
        ),
      ],
    },
    hardcodedFields
  )
}

function hasObjectTruthyValue(obj) {
  return _.some(Object.values(obj), (value) =>
    _.isObject(value) ? hasObjectTruthyValue(value) : !!value
  )
}

export function convertFieldsToRequest(
  fields,
  extractors = { [REQUEST_INPUT_TYPE.FORM]: [], [REQUEST_INPUT_TYPE.RAW]: [] },
  validators = { [REQUEST_INPUT_TYPE.FORM]: [], [REQUEST_INPUT_TYPE.RAW]: [] },
  hardcodedFields
) {
  const { errors } = getErrors(fields, ...validators[fields.requestInputType])

  const hasError = hasObjectTruthyValue(errors)
  const request = !hasError
    ? _.merge(
        {},
        ...extractors[fields.requestInputType].map((extractor) =>
          extractor(fields)
        ),
        hardcodedFields
      )
    : null
  return {
    request,
    errors,
    hasError,
  }
}
