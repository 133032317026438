import { CustomizrClient, getMcpSettings } from 'cimpress-customizr'

const client = new CustomizrClient({
  // The resource you'd like to get/put the settings from/to.
  // You can either provide it here or directly in the get/putSettings methods
  resource: 'shiprec-ui',

  // The address of Cimpress Customizr service
  baseUrl: 'https://customizr.at.cimpress.io',

  // How many try to retry the request in case of network error or 5xx response
  retryAttempts: 2,

  // How long to wait between retries in milliseconds
  retryDelayInMs: 1000,
})

export const getGlobalSettings = getMcpSettings

export default client
