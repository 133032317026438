import axios from 'axios'

const loadFulfillersNames = async (token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_FULFILLER_IDENTITY_API_SERVICE_URL}/v1/fulfillers/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return response.data
}

export default loadFulfillersNames
