import { useCallback } from 'react'
import { useLocation, generatePath } from 'react-router-dom'

const stringifyQuery = (query) => {
  return typeof query === 'object'
    ? Object.entries(query)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')
    : query.toString()
}

const usePath = () => {
  const { protocol, host } = window.location
  const { pathname } = useLocation()

  const generateUrl = useCallback(
    (query) => {
      const path = generatePath(pathname)
      const search = query ? `?${stringifyQuery(query)}` : ''
      const url = `${protocol}//${host}${path}${search}`
      return url
    },
    [pathname, protocol, host]
  )
  return {
    pathname,
    generateUrl,
  }
}

export default usePath
