import React, { createContext } from 'react'
import useSupportedCurrencies from '../hooks/useCurrenciesList'

export const SupportedCurrenciesContext = createContext({
  error: undefined,
  isLoading: false,
  supportedCurrencies: undefined,
  load: async () => {
    throw new Error('SupportedCurrenciesContext not defined')
  },
})

export const SupportedCurrenciesProvider = ({ children, value }) => {
  const {
    load,
    error,
    isLoading,
    supportedCurrencies,
  } = useSupportedCurrencies()

  const context = {
    supportedCurrencies: value || supportedCurrencies,
    error,
    isLoading,
    load,
  }

  return (
    <SupportedCurrenciesContext.Provider value={context}>
      {children}
    </SupportedCurrenciesContext.Provider>
  )
}

export default SupportedCurrenciesContext
