import axios from 'axios'
import _ from 'lodash'
const BATCH_SIZE = 3
const PAGE_SIZE = 200

const loadCarrierServices = async (token) => {
  let seenEmpty = false
  let currentPage = 1
  let result = []
  while (!seenEmpty) {
    const promises = []
    for (let i = 0; i < BATCH_SIZE; i++) {
      promises.push(
        axios.get(
          `${process.env.REACT_APP_CARRIERS_SERVICE_URL}/carrierServices?size=${PAGE_SIZE}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      )
      currentPage++
    }
    const resolvedRequest = await Promise.all(promises)
    result = result.concat(
      // eslint-disable-next-line no-loop-func
      resolvedRequest.reduce((acc, curr) => {
        if (_.isEmpty(curr.data.carrierServices)) {
          seenEmpty = true
          return acc
        }
        return [...acc, ...curr.data.carrierServices]
      }, [])
    )
  }
  return result
}

export default loadCarrierServices
