import React, { useEffect } from 'react'
import { shapes } from '@cimpress/react-components'

import RequestForm from '../RequestForm/RequestForm'
import ResultPane from '../ResultPane/ResultPane'
import ShipRecResult from '../ShipRecResult/ShipRecResult'
import ShipRecForm from '../RequestForm/ShipRecForm/ShipRecForm'
import useRecommendations from '../../hooks/useRecommendations'

const { Robot } = shapes

export const ShipRecApp = ({ onError = () => {} }) => {
  const { data, error, clear, isLoading, load } = useRecommendations()

  useEffect(() => () => clear(), [clear])
  useEffect(() => {
    error && onError(error)
  }, [error, onError])

  return (
    <>
      <RequestForm
        submitLabel="Show me the cost estimates"
        FormComponent={ShipRecForm}
        onSubmit={load}
      />
      <ResultPane
        ResultComponent={ShipRecResult}
        AppIcon={<Robot bsStyle="success" alternate />}
        resultName="shipping recommendation"
        isLoading={isLoading}
        result={data}
      />
    </>
  )
}
