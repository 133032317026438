import axios from 'axios'

const loadRecommendationsPackages = async (request, token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PLANNING_API_SERVICE_URL}/v5/recommendations/packages:calculate`,
    request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data
}

const loadRecommendationsPacks = async (request, token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PLANNING_API_SERVICE_URL}/v5/recommendations/packs:calculate`,
    request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data
}

const loadDeliverableDatesPackages = async (request, token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PLANNING_API_SERVICE_URL}/v2/deliverableDates/packages:calculate`,
    request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data
}

const loadDeliverableDatesPacks = async (request, token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PLANNING_API_SERVICE_URL}/v2/deliverableDates/packs:calculate`,
    request,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data
}

export {
  loadRecommendationsPackages,
  loadDeliverableDatesPackages,
  loadRecommendationsPacks,
  loadDeliverableDatesPacks,
}
