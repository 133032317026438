import { useContext, useState, useCallback } from 'react'
import loadFulfillmentLocations from '../services/fulfillmentLocations'
import AuthContext from '../context/AuthContext'

const useFulfillmentLocations = () => {
  const [fulfillmentLocations, setFulfillmentLocations] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [isloading, setIsLoading] = useState(false)

  const { token } = useContext(AuthContext)

  const load = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    setFulfillmentLocations(undefined)
    try {
      const list = await loadFulfillmentLocations(token)
      setFulfillmentLocations(list)
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [token])
  return { fulfillmentLocations, load, error, isloading }
}

export default useFulfillmentLocations
