import { useCallback, useState } from 'react'
import appSettingsClient from '../services/appSettingsClient'

const defaultAppSettings = {
  lengthUnit: 'cm',
  weightUnit: 'kg',
}

const useAppSettings = (token) => {
  const [appSettings, setAppSettings] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const load = useCallback(async () => {
    setIsLoading(true)
    const settings = await appSettingsClient.getSettings(token)
    setAppSettings({
      ...defaultAppSettings,
      ...settings,
    })
    setIsLoading(false)
  }, [token])

  return {
    appSettings,
    setAppSettings: async (newAppSettings) => {
      setAppSettings(newAppSettings)
      await appSettingsClient.putSettings(token, newAppSettings)
    },
    load,
    isLoading,
  }
}

export default useAppSettings
