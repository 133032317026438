import React from 'react'
import { FlexBox } from '@cimpress/react-components'

import styles from './RequirementsInput.module.css'
import { TimeInput } from '../TimeInput/TimeInput'

export const MATCHING_MODES = {
  before: { value: 'before', label: 'Before' },
}

const ScheduledDeliveryParams = ({ value, onChange, required, errors }) => {
  return (
    <>
      <h5>For Scheduled delivery, it delivers before</h5>
      <div
        className={[
          styles.scheduledDelivery,
          'cash-on-delivery-input',
          'form-group',
          errors && errors.before ? 'has-error' : '',
        ].join(' ')}
      >
        <FlexBox middle marginX="m">
          <TimeInput
            value={value.before}
            onChange={(time) => {
              onChange({ ...value, before: time })
            }}
            required={required}
            errors={errors && errors.before}
          />
        </FlexBox>
      </div>
    </>
  )
}

export default ScheduledDeliveryParams
