import { centralizedAuth } from '@cimpress/simple-auth-wrapper'

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  expirationOffset: 60 * 60, // By default token is refreshed 30 seconds before expiration, but that is proving unreliable. This line will force token refresh every hour, mitigating the issue.
})

export default auth
