import React from 'react'
import { last } from 'lodash'
import PackageInput from './PackageInput'
import { SHIPMENT_TYPES } from '../ShipmentInput'
import styles from '../ShipmentInput.module.css'

const PackageList = ({ value, onChange, errors, className, type }) => {
  const addPackage = () => {
    value.push(last(value))
    onChange(value)
  }

  const createRemovePackage = (index) => () => {
    value.splice(index, 1)
    onChange(value)
  }

  const createOnPackageUpdate = (index) => (container) => {
    value.splice(index, 1, container)
    onChange(value)
  }

  const addPackageText =
    type === SHIPMENT_TYPES.PALLET
      ? 'Add another pallet'
      : 'Add another package'

  return (
    <>
      <div className={`${className} ${styles.packagesInput}`}>
        {value.map((container, index, packages) => {
          return (
            <div
              className={`col col-lg-12 ${styles.packageInputLine}`}
              key={`dimensionForm${index}`}
            >
              <PackageInput
                packageType={type}
                value={container}
                rank={index}
                errors={errors && errors[index]}
                onChange={createOnPackageUpdate(index)}
                onRemove={createRemovePackage(index)}
                singleRow={packages.length === 1}
              />
            </div>
          )
        })}
      </div>
      <div className={className}>
        <div className="col col-lg-12">
          <span onClick={addPackage} className="clickable btn-link">
            {addPackageText}
          </span>
        </div>
      </div>
    </>
  )
}

export default PackageList
