import _ from 'lodash'
import { DESTINATION_TYPES } from '../DestinationInput/DestinationInput'
import moment from 'moment'
import AVAILABLE_CAPABILITIES from '../RequirementsInput/availableCapabilities'
import { MATCHING_MODES } from '../RequirementsInput/ScheduledDeliveryParams'

import {
  isPackagesRequest,
  isPackRequest,
} from '../../../services/packsOrPackages'

export const ERROR_TYPE_REQUIRED = 'required'
export const ERROR_TYPE_INVALID_TIME = 'invalid_time'
export const ERROR_TYPE_INVALID_VALUE = 'invalid_value'
export const ERROR_TYPE_INVALID_JSON = 'invalid_json'
export const ERROR_TYPE_CANNOT_DETERMINE_REQUEST_TYPE =
  'cannot_determine_request_type'
export const ERROR_TYPE_INVALID_MAX_RECOMMENDATIONS =
  'invalid_max_recommendations'

export const parseTime = (time) => moment(time, ['h:mm a', 'H:mm'], true)
const isValidTime = (time) => parseTime(time).isValid()

function combineErrors(...args) {
  const errors = args.reduce((acc, errors) => {
    acc.push(errors)
    return acc
  }, [])
  return {
    errors: errors.reduce((acc, curr) => {
      Object.keys(curr).forEach((key) => {
        if (_.isNil(acc[key])) {
          acc[key] = curr[key]
        }
      })
      return acc
    }, {}),
  }
}

function getRequiredError(path, fields) {
  return _.isEmpty(_.get(fields, path, null)) ? ERROR_TYPE_REQUIRED : null
}

function setRequiredError(errors, path, fields) {
  const error = getRequiredError(path, fields)
  if (error) {
    Object.assign(errors, _.set(errors, path, error))
  }
}

export const fulfillmentLocationValidator = (namespace) => (fields) => {
  const errors = {}
  setRequiredError(errors, namespace, fields)

  return errors
}
export const shippingDestinationValidator = (namespace) => (fields) => {
  const errors = {}

  const pickupPointUrlPath = `${namespace}.pickupPointUrl`
  const destinationTypePath = `${namespace}.destinationType`

  if (_.get(fields, destinationTypePath) === DESTINATION_TYPES.PICKUP_POINT) {
    setRequiredError(errors, pickupPointUrlPath, fields)
  }

  return errors
}
export const shipmentValidator = (namespace) => (fields) => {
  const errors = {}
  const shipmentItemsPath = `${namespace}.shipmentItems`
  const shipmentItems = _.get(fields, shipmentItemsPath, [])
  for (const i in shipmentItems) {
    setRequiredError(errors, `${shipmentItemsPath}[${i}].dimension1`, fields)
    setRequiredError(errors, `${shipmentItemsPath}[${i}].dimension2`, fields)
    setRequiredError(errors, `${shipmentItemsPath}[${i}].dimension3`, fields)
    setRequiredError(errors, `${shipmentItemsPath}[${i}].weight`, fields)
    if (_.get(fields, `${shipmentItemsPath}[${i}].hasShipmentValue`, false)) {
      setRequiredError(
        errors,
        `${shipmentItemsPath}[${i}].shipmentValue.scalar`,
        fields
      )
    }
  }

  return errors
}

export const requireValidator = (namespace) => (fields) => {
  const errors = {}

  const scheduledDeliveryPath = `${namespace}.capabilityParams.${AVAILABLE_CAPABILITIES.scheduledDelivery.value}`
  const capabilitiesPath = `${namespace}.capabilities`
  const cashOnDeliveryPath = `${namespace}.capabilityParams.${AVAILABLE_CAPABILITIES.cashOnDelivery.value}`

  const containsCapability = (searchedCapability) =>
    _.get(fields, capabilitiesPath, []).includes(searchedCapability)

  const scheduledDeliveryMatchingModePath = `${scheduledDeliveryPath}.matchingMode`
  const scheduledDeliveryMatchingMode = _.get(
    fields,
    scheduledDeliveryMatchingModePath
  )
  const scheduledDeliveryBeforePath = `${scheduledDeliveryPath}.before`
  if (containsCapability(AVAILABLE_CAPABILITIES.scheduledDelivery.value)) {
    if (!isValidTime(_.get(fields, scheduledDeliveryBeforePath))) {
      _.set(errors, scheduledDeliveryBeforePath, ERROR_TYPE_INVALID_TIME)
    }

    setRequiredError(errors, scheduledDeliveryMatchingModePath, fields)
    setRequiredError(errors, scheduledDeliveryBeforePath, fields)
    if (
      scheduledDeliveryMatchingMode &&
      scheduledDeliveryMatchingMode !== MATCHING_MODES.before.value
    ) {
      _.set(errors, scheduledDeliveryMatchingModePath, ERROR_TYPE_INVALID_VALUE)
    }
  }
  if (containsCapability(AVAILABLE_CAPABILITIES.cashOnDelivery.value)) {
    setRequiredError(errors, `${cashOnDeliveryPath}.scalar`, fields)
  }

  return errors
}

export const dateTimeValidator = (namespace) => (fields) => {
  const errors = {}

  const timePath = `${namespace}.time`
  if (_.has(fields, timePath)) {
    const time = _.get(fields, timePath, '')
    if (!isValidTime(time)) {
      _.set(errors, timePath, ERROR_TYPE_INVALID_TIME)
    }
    setRequiredError(errors, timePath, fields)
  }
  return errors
}

export const sortAndLimitValidator = (namespace) => (fields) => {
  const errors = {}
  const maxNumberPath = `${namespace}.maxNumberOfRecommendations`

  const maxNumberValue = Number.parseInt(_.get(fields, maxNumberPath))
  if (isNaN(maxNumberValue) || maxNumberValue < 1) {
    _.set(errors, maxNumberPath, ERROR_TYPE_INVALID_MAX_RECOMMENDATIONS)
  }
  setRequiredError(errors, maxNumberPath, fields)
  return errors
}

export const rawRequestValidator = (
  namespace,
  packagesSchemaPath,
  packsSchemaPath,
  swaggerValidate
) => (fields) => {
  const errors = {}
  const rawRequest = fields[namespace]

  try {
    const parsedRequest = JSON.parse(rawRequest)
    let validationSchemaPath
    if (isPackagesRequest(parsedRequest)) {
      validationSchemaPath = packagesSchemaPath
    } else if (isPackRequest(parsedRequest)) {
      validationSchemaPath = packsSchemaPath
    } else {
      errors[namespace] = ERROR_TYPE_CANNOT_DETERMINE_REQUEST_TYPE
    }

    if (validationSchemaPath) {
      const { isValid, errors: ajvErrors } = swaggerValidate(
        validationSchemaPath,
        parsedRequest
      )
      if (!isValid) {
        errors[namespace] = ajvErrors
      }
    }
  } catch (e) {
    errors[namespace] = ERROR_TYPE_INVALID_JSON
  }

  return errors
}
export function getErrors(fields, ...validators) {
  return combineErrors(...validators.map((validator) => validator(fields)))
}
