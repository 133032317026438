import _ from 'lodash'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssLoader } from '@cimpress/react-components'
import Header from '@cimpress-technology/react-platform-header'
import MainPage from './components/MainPage'
import useAuth from './hooks/useAuth'
import AuthContext from './context/AuthContext'
import GlobalSpinner from './components/GlobalSpinner/GlobalSpinner'

import styles from './App.module.css'
import AppSettings from './AppSettings'
import AppSettingsContext from './context/AppSettingsContext'
import useAppSettings from './hooks/useAppSettings'

function App() {
  const { isLoggedIn, isAuthenticating, auth } = useAuth()
  const authToken = auth.getAccessToken()
  const {
    load: loadAppSettings,
    isLoading: isAppSettingsLoading,
    appSettings,
    setAppSettings,
  } = useAppSettings(authToken)
  const updateAppSettings = async (newSettings) => {
    if (!_.isEqual(newSettings, appSettings)) {
      await setAppSettings(newSettings)
      window.location.reload() // refresh page after save
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      loadAppSettings()
    }
  }, [loadAppSettings, isLoggedIn, authToken])

  return (
    <BrowserRouter>
      <CssLoader>
        <div className={styles.AppLayout}>
          <AuthContext.Provider value={{ token: authToken }}>
            <AppSettingsContext.Provider value={appSettings}>
              <Header
                responsive
                appTitle="Shipping Recommendation"
                isLoggedIn={isLoggedIn}
                profile={auth.getProfile()}
                onLogInClicked={() => auth.login()}
                onLogOutClicked={() => auth.logout('/')}
                appLinks={[
                  {
                    id: 'settings',
                    content:
                      isLoggedIn && !isAppSettingsLoading ? (
                        <AppSettings setAppSettings={updateAppSettings} />
                      ) : (
                        undefined
                      ),
                  },
                ]}
              />
              <GlobalSpinner
                visible={
                  isAuthenticating || !isLoggedIn || isAppSettingsLoading
                }
              >
                <MainPage />
              </GlobalSpinner>
            </AppSettingsContext.Provider>
          </AuthContext.Provider>
        </div>
      </CssLoader>
    </BrowserRouter>
  )
}

export default App
