import React from 'react'
import SelectValue from './SelectValue'
import _ from 'lodash'

const PLACEHOLDER = 'placeholder'

function shouldApplyPlaceholder(value, placeholder) {
  return !!_.isEmpty(value) && placeholder
}

export default function SelectMultipleWithPlaceholder(props) {
  const { values = [], isClearable, placeholder, onChange } = props

  return (
    <SelectValue
      {...props}
      value={
        shouldApplyPlaceholder(values, placeholder)
          ? { value: PLACEHOLDER, label: placeholder }
          : values
      }
      onChange={(value) =>
        shouldApplyPlaceholder(values, placeholder) && value
          ? onChange([value])
          : onChange(value)
      }
      isMulti={shouldApplyPlaceholder(values, placeholder) ? false : true}
      isClearable={
        shouldApplyPlaceholder(values, placeholder) ? false : isClearable
      }
    />
  )
}
