import React from 'react'
import arrayMove from 'array-move'

import { Card } from '@cimpress/react-components'
import {
  IconArrowUp,
  IconArrowDown,
} from '@cimpress-technology/react-streamline-icons/lib'
import { alloy, teal } from '@cimpress/react-components/lib/colors'

import styles from './PrioritiesSorter.module.css'

export default function PrioritiesSorter(props) {
  const isFirst = (index) => index === 0
  const isLast = (index) => index + 1 === props.strategyPriorities.length

  const moveUp = (index) => {
    if (!isFirst(index)) {
      props.onChange(arrayMove(props.strategyPriorities, index, index - 1))
    }
  }

  const moveDown = (index) => {
    if (!isLast(index)) {
      props.onChange(arrayMove(props.strategyPriorities, index, index + 1))
    }
  }
  const findOption = (option) =>
    Object.values(props.options).find(({ value }) => value === option)

  return (
    <div>
      <h6 className={`text-muted ${styles.header}`}>{props.title}</h6>
      {props.strategyPriorities.map((priority, index) => (
        <Card key={priority}>
          {findOption(priority).name}
          <IconArrowUp
            className={`pull-right ${styles.arrow}`}
            color={isFirst(index) ? alloy : teal.base}
            weight={'fill'}
            onClick={() => moveUp(index)}
          />
          <IconArrowDown
            className={`pull-right ${styles.arrow}`}
            color={isLast(index) ? alloy : teal.base}
            weight={'fill'}
            onClick={() => moveDown(index)}
          />
        </Card>
      ))}
    </div>
  )
}
