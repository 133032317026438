import { useCallback, useRef } from 'react'

export function useAutoScroll(ref) {
  const elementRef = useRef(ref)

  const scrollToElement = useCallback(
    (
      options = {
        block: 'start',
        behavior: 'smooth',
      }
    ) => {
      if (elementRef.current) {
        setImmediate(
          () => elementRef.current && elementRef.current.scrollIntoView(options)
        )
      }
    },
    [elementRef]
  )
  return {
    elementRef,
    scrollToElement,
  }
}
