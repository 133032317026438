import React, { useMemo } from 'react'
import { SelectWrapper } from '@cimpress/react-components'
import _ from 'lodash'
import CreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import styles from '@cimpress/react-components/lib/Select/styles'
export default function SelectValue(props) {
  const {
    options = [],
    value,
    onChange,
    isMulti,
    isCreatable,
    createLabel,
  } = props
  const convertValueToSelectedOption = useMemo(
    () => (val) =>
      options.find(({ value: optionValue }) => _.isEqual(optionValue, val)) ||
      val,
    [options]
  )

  const selectValue = useMemo(
    () =>
      isMulti
        ? (value || []).map(convertValueToSelectedOption)
        : convertValueToSelectedOption(value),
    [value, isMulti, convertValueToSelectedOption]
  )
  return (
    <SelectWrapper
      {...props}
      formatCreateLabel={createLabel}
      selectedSelect={isCreatable ? CreatableSelect : ReactSelect}
      styles={styles}
      placeholder={props.label}
      value={selectValue}
      onChange={(selectValue) => {
        onChange(
          isMulti
            ? (selectValue || []).map((single) => single.value)
            : _.get(selectValue, 'value', null)
        )
      }}
    />
  )
}
