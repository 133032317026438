import React, { useState } from 'react'
import { Button, Modal, TextField } from '@cimpress/react-components'
import styles from './PickupPointInput.module.css'
import Widget from '@cimpress-technology/pup-ui-widget'
import { ERROR_TYPE_REQUIRED } from '../utils/formErrorHandling'

export default function PickupPointInput({
  value,
  onChange,
  errors,
  required,
}) {
  const [isOpened, setIsOpened] = useState(false)

  const openModal = () => {
    setIsOpened(true)
  }
  const closeModal = () => {
    setIsOpened(false)
  }

  return (
    <div className="row">
      <Modal
        className={styles.modal}
        show={isOpened}
        onRequestHide={closeModal}
        title="Select pickup point"
        bsSize={'lg'}
        closeOnOutsideClick={true}
        closeButton={true}
      >
        <div className={styles.contentWrapper}>
          <Widget
            googleApiKey="AIzaSyBQ3r1fOmIIdNzYi3QBF5lV6lLihRi4_DM"
            onPickupPointSelection={({ pickupPoint }) => {
              onChange(pickupPoint._links.self.href)
              closeModal()
            }}
          />
        </div>
      </Modal>
      <div className="col-xs-12">
        <TextField
          className={`${styles.lowBottomMargin} pickup-point-input`}
          label="PickupPointUrl"
          value={value}
          onChange={(result) => {
            onChange(result.target.value)
          }}
          required={required}
          bsStyle={errors ? 'error' : ''}
          helpText={
            errors && errors === ERROR_TYPE_REQUIRED
              ? 'Please enter pickup point URL'
              : ''
          }
          rightAddon={
            <Button type="primary" size={'lg'} onClick={openModal}>
              Find on map
            </Button>
          }
        />
      </div>
    </div>
  )
}
