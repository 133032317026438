import axios from 'axios'

const loadFulfillmentLocations = async (token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_FULFILLMENT_LOCATIONS_SERVICE_URL}/v1/fulfillmentlocations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response.data
}

export default loadFulfillmentLocations
