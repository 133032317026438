import React, { useContext } from 'react'
import {
  Card,
  Label,
  Tooltip,
  shapes,
  ReactTablev6,
} from '@cimpress/react-components'
import styles from './DeliverableDatesResult.module.css'
import moment from 'moment'
import LocaleContext from '../../context/LocaleContext'
import { renderDisplayCurrency } from '../../services/currency'
import _ from 'lodash'
import resultPaneStyles from '../ResultPane/ResultPane.module.css'
const { Robot } = shapes

const getLowestShippingCost = (deliverableDate) =>
  Number(
    deliverableDate.cheapestOptionOverall.costEstimate.unifiedCurrency.shipping
  )
const getCurrencyCode = (deliverableDate) =>
  deliverableDate.cheapestOptionOverall.costEstimate.unifiedCurrency
    .currencyCode
function mapData(deliverableDates) {
  const lowestCost = getLowestShippingCost(
    _.minBy(deliverableDates, getLowestShippingCost)
  )
  return deliverableDates.map((deliverableDate) => {
    return {
      deliveryDate: moment(deliverableDate.deliveryDate),
      cheapestCarrierService: {
        key: deliverableDate.cheapestOptionOverall.carrierServiceKey,
        name: deliverableDate.cheapestOptionOverall.carrierServiceName,
      },
      cost: {
        isLowestCost: getLowestShippingCost(deliverableDate) === lowestCost,
        scalar: getLowestShippingCost(deliverableDate),
        currencyCode: getCurrencyCode(deliverableDate),
      },
    }
  })
}

const sortByDate = (a, b) => {
  if (a.isSame(b, 'day')) {
    return 0
  }
  return a.isAfter(b, 'day') ? 1 : -1
}

export default function DeliverableDatesResult({ result: deliverableDates }) {
  const { locale } = useContext(LocaleContext)
  if (_.isEmpty(deliverableDates)) {
    return (
      <div
        className={`${resultPaneStyles.resultContainer} ${resultPaneStyles.centerVertically}`}
      >
        <span className={resultPaneStyles.centerIcon}>
          <Robot bsStyle="warning" alternate />
        </span>
        <p className={resultPaneStyles.resultText}>
          Unfortunately, there are no deliverable dates for what you would like
          to ship.
          <br />
          Try to modify the request.
        </p>
      </div>
    )
  }
  const COLUMNS = [
    {
      Header: 'Delivery date',
      accessor: 'deliveryDate',
      defaultSortDesc: true,
      className: styles.tableColumn,
      Cell: function renderDeliveryDate(row) {
        return (
          <Tooltip
            direction="top"
            contents={row.value.locale(locale).format('dddd')}
          >
            {row.value.locale(locale).format('LL')}{' '}
            {row.index === 0 ? <Label text="fastest" type="info" /> : ''}
          </Tooltip>
        )
      },
      sortMethod: sortByDate,
    },
    {
      Header: 'Cheapest carrier service on this date',
      accessor: 'cheapestCarrierService',
      className: styles.tableColumn,
      Cell: function renderCarrierService(row) {
        return (
          <Tooltip direction="top" contents={row.value.key}>
            {row.value.name}
          </Tooltip>
        )
      },
    },
    {
      Header: 'Lowest cost on this date',
      accessor: 'cost',
      maxWidth: 250,
      className: styles.tableColumn,
      Cell: function renderCost(row) {
        return (
          <span>
            {renderDisplayCurrency(
              row.value.scalar,
              row.value.currencyCode,
              locale
            )}{' '}
            {row.value.isLowestCost ? (
              <Label text="cheapest" type="success" />
            ) : (
              ''
            )}
          </span>
        )
      },
      sortMethod: (a, b) => Number(b.scalar) - Number(a.scalar),
    },
  ]
  return (
    <Card className={styles.tableWrapper}>
      <ReactTablev6
        showPageSizeOptions={false}
        className={styles.table}
        pageSize={20}
        minRows={10}
        columns={COLUMNS}
        data={mapData(deliverableDates)}
      />
    </Card>
  )
}
