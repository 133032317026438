import React from 'react'
import _ from 'lodash'
import { TextField } from '@cimpress/react-components'

import styles from './RawRequest.module.css'
import {
  ERROR_TYPE_CANNOT_DETERMINE_REQUEST_TYPE,
  ERROR_TYPE_INVALID_JSON,
} from '../utils/formErrorHandling'

function getErrorText(error) {
  switch (error) {
    case ERROR_TYPE_INVALID_JSON:
      return 'The input is not a valid JSON'
    case ERROR_TYPE_CANNOT_DETERMINE_REQUEST_TYPE:
      return 'Please make sure that your request contains either packages or packDefinitions'
    default:
      return error
  }
}

export default function RawRequest({
  value,
  onChange,
  errors,
  hardcodedFields,
}) {
  const optionsList = Object.keys(_.get(hardcodedFields, 'options', {}))

  const disclaimer = optionsList.length ? (
    <p>
      This tool requires {optionsList.length === 1 ? 'option' : 'options'}{' '}
      {optionsList
        .map((option) => <em key={option}>{option}</em>)
        .reduce((prev, curr) => [prev, ', ', curr])}{' '}
      to be present. It will be set automatically
    </p>
  ) : null

  return (
    <>
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.jsoninput}
        type="textarea"
        bsStyle={errors ? 'error' : ''}
        helpText={errors ? getErrorText(errors) : ''}
      />
      {disclaimer}
    </>
  )
}
