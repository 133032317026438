import React, { useEffect, useState, useCallback } from 'react'
import { FlexBox, Snackbar } from '@cimpress/react-components'
import { Route, Redirect, Switch, generatePath } from 'react-router-dom'

import GlobalSpinner from './GlobalSpinner/GlobalSpinner'
import useFulfillmentLocations from '../hooks/useFulfillmentLocations'
import useSupportedCurrencies from '../hooks/useCurrenciesList'
import useCarrierServices from '../hooks/useCarrierServices'
import { FulfillmentLocationsProvider } from '../context/FulfillmentLocationsContext'
import { SupportedCurrenciesProvider } from '../context/CurrenciesListContext'
import { CarrierServicesProvider } from '../context/CarrierServicesContext'
import { OnDemandServicesProvider } from '../context/OnDemandServicesContext'
import { FormContextProvider } from '../context/FormContext'
import styles from './MainPage.module.css'
import useFulfillersNames from '../hooks/useFulfillersNames'
import { FulfillersNamesProvider } from '../context/FulfillersNamesContext'
import { ShipRecApp } from './AppTypes/ShipRecApp'
import { DeliveryDatesApp } from './AppTypes/DeliveryDatesApp'
import GlobalError from './GlobalError/GlobalError'
import { translateError } from '../services/errors'
import { ConfigurationDiagnosticsApp } from './AppTypes/ConfigurationDiagnosticsApp'
import useSwaggerSchema from '../hooks/useSwaggerSchema'
import { SwaggerSchemaContextProvider } from '../context/SwaggerSchemaContext'

export const APP_TYPE = {
  SHIP_REC: {
    path: '/shippingRecommendation',
    name: 'Shipping Recommendation',
    component: ShipRecApp,
  },
  DELIVERABLE_DATES: {
    path: '/deliverableDates',
    name: 'Possible delivery dates',
    component: DeliveryDatesApp,
  },
  CONFIGURATION_DIAGNOSTICS: {
    path: '/configurationDiagnostics',
    name: 'Configuration diagnostics',
    component: ConfigurationDiagnosticsApp,
  },
}

const MainPage = () => {
  const {
    load: loadFulfillmentLocations,
    isLoading: isFulfillmentLocationsLoading,
    error: fulfillmentLocationError,
    fulfillmentLocations,
  } = useFulfillmentLocations()
  const {
    load: loadSupportedCurrencies,
    isLoading: isSupportedCurrenciesLoading,
    error: supportedCurrenciesError,
    supportedCurrencies,
  } = useSupportedCurrencies()
  const {
    load: loadFulfillersNames,
    isLoading: isFulfillersNamesLoading,
    error: fulfillersNamesError,
    fulfillersNames,
  } = useFulfillersNames()
  const {
    load: loadCarrierServices,
    isLoading: isCarrierServicesLoading,
    error: carrierServicesError,
    carrierServices,
  } = useCarrierServices()
  const {
    schema,
    load: loadSchema,
    isLoading: isSchemaLoading,
    error: loadSchemaError,
  } = useSwaggerSchema()

  const [errorSnackbar, setErrorSnackbar] = useState({
    visible: false,
    message: '',
  })

  const toggleErrorSnackbar = useCallback((err) => {
    err && console.error(err)
    setErrorSnackbar((snackbar) => ({
      ...snackbar,
      visible: !!err,
      message: err && translateError(err),
    }))
  }, [])

  useEffect(() => {
    loadFulfillersNames()
    loadCarrierServices()
    loadSupportedCurrencies()
    loadFulfillmentLocations()
    loadSchema()
  }, [
    loadFulfillmentLocations,
    loadCarrierServices,
    loadSupportedCurrencies,
    loadFulfillersNames,
    loadSchema,
  ])

  const globalError =
    fulfillmentLocationError ||
    supportedCurrenciesError ||
    fulfillersNamesError ||
    carrierServicesError ||
    loadSchemaError

  const isGlobalSpinnerVisible =
    isFulfillmentLocationsLoading ||
    isSupportedCurrenciesLoading ||
    isFulfillersNamesLoading ||
    isCarrierServicesLoading ||
    isSchemaLoading

  return (
    <GlobalSpinner visible={isGlobalSpinnerVisible}>
      <Snackbar
        show={errorSnackbar.visible}
        bsStyle="danger"
        onHideSnackbar={() => toggleErrorSnackbar()}
      >
        {errorSnackbar.message}
      </Snackbar>
      <GlobalError error={globalError}>
        <FulfillmentLocationsProvider value={fulfillmentLocations}>
          <SwaggerSchemaContextProvider schema={schema}>
            <FulfillersNamesProvider value={fulfillersNames}>
              <SupportedCurrenciesProvider value={supportedCurrencies}>
                <CarrierServicesProvider value={carrierServices}>
                  <OnDemandServicesProvider value={carrierServices}>
                    <FormContextProvider>
                      <FlexBox className={styles.content}>
                        <Switch>
                          {Object.values(APP_TYPE).map(
                            ({ path, component: AppComponent }, index) => (
                              <Route key={index} exact path={path}>
                                <AppComponent onError={toggleErrorSnackbar} />
                              </Route>
                            )
                          )}
                          <Route path="*">
                            <Redirect
                              to={generatePath(APP_TYPE.SHIP_REC.path)}
                            />
                          </Route>
                        </Switch>
                      </FlexBox>
                    </FormContextProvider>
                  </OnDemandServicesProvider>
                </CarrierServicesProvider>
              </SupportedCurrenciesProvider>
            </FulfillersNamesProvider>
          </SwaggerSchemaContextProvider>
        </FulfillmentLocationsProvider>
      </GlobalError>
    </GlobalSpinner>
  )
}
export default MainPage
