import React from 'react'
import { Alert } from '@cimpress/react-components'
import _ from 'lodash'

function renderProblems(problems) {
  return (
    <ul>
      {problems.map((problem) => (
        <li key={problem.carrierService}>
          <b>{problem.carrierService}</b>: {problem.message}{' '}
        </li>
      ))}
    </ul>
  )
}

export function ShippingFeasibilityError({ shippingFeasibility }) {
  if (_.get(shippingFeasibility, 'status') === 'invalid') {
    return (
      <Alert
        dismissible={false}
        title={'Shipping feasibility check error'}
        type={'warning'}
        message={
          <>
            <p>
              We have tried to validate if the carrier services will be able to
              ship to the provided address and some of the carrier services
              reported problems. These carrier services were omitted from the
              result.
            </p>
            <p>
              The validation is specific for each carrier and therefore it can
              happen, that one address is valid for some carriers, but
              considered invalid for others.
            </p>
            <p>Here is the list of carrier services and problems we found:</p>
            {renderProblems(shippingFeasibility.problems)}

            <p>
              For information how to resolve the shipping feasibility issues,
              please check the{' '}
              <a
                href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1544455984/How+to+resolve+shipping+feasibility+issues?atlOrigin=eyJpIjoiNzgyZGFmMDFiY2M2NDNhYTk3NTJhYjgzZTE1ZjU3M2YiLCJwIjoiYyJ9"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to resolve shipping feasibility issues
              </a>{' '}
              page
            </p>
            <p>
              For more information about the shipping feasibility, please visit
              our{' '}
              <a
                href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/903644108/Shipping+Feasibility+validation?atlOrigin=eyJpIjoiYTJiZWM4OTk3YzNhNGUxZjliMDlkNjc3MGRjODc2YjYiLCJwIjoiYyJ9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Knowledge base
              </a>
              .
            </p>
          </>
        }
      />
    )
  } else {
    return null
  }
}
